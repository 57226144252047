import * as React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_INDEX_NAME,
  ALGOLIA_SEARCH_KEY,
} from "../config/constans";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  RefinementList,
  Snippet,
  PoweredBy,
  Stats,
} from "react-instantsearch-dom";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import { Meta } from "../components/Meta";
import { theme } from "../components/theme";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

interface SearchProps {}

const Search: React.FC<SearchProps> = () => {
  const Hit = (data: any) => {
    const hit = data.hit;
    return (
      <ResultsContent onClick={() => navigate(`${hit.url}`)}>
        <div>
          <img alt={"searchImage"} src={`${hit.imageURL}?w=300`} />
        </div>

        <div>
          <h3>{hit.title}</h3>

          <div>
            <div>
              <div>{`writen by ${hit.author}`}</div>
            </div>
          </div>

          <div>
            {hit.categories.map((item: string, index: number) => {
              return <CategoryBadge key={index}>{item}</CategoryBadge>;
            })}
          </div>

          <p>{hit.body}</p>
        </div>
      </ResultsContent>
    );
  };

  return (
    <Layout>
      <Meta />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
          integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css"
          integrity="sha256-HB49n/BZjuqiCtQQf49OdZn63XuKFaxcIHWf0HNKte8="
          crossOrigin="anonymous"
        />

        <title>kame's blog - Search</title>
      </Helmet>

      <InstantSearch indexName={ALGOLIA_INDEX_NAME} searchClient={searchClient}>
        <Container>
          <h1 style={{ width: "100%", textAlign: "center" }}>Search Posts</h1>

          <SearchContainer>
            <SearchBox searchAsYouType={true} showLoadingIndicator={true} />
            <Stats />
            <Category attribute="categories" />
          </SearchContainer>
          <ResultsContainer>
            <Hits hitComponent={Hit} />
          </ResultsContainer>
          <FooterContainer>
            <Pagination />
            <PoweredBy />
          </FooterContainer>
        </Container>
      </InstantSearch>
    </Layout>
  );
};

export default Search;

const Container = styled.div`
  margin: 0 auto;
  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.main};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const SearchContainer = styled.div`
  margin: 24px 0 0;
  padding: 15px;
  width: 100%;

  & > :nth-child(1) {
    margin-bottom: 4px;

    input {
      height: 40px;
    }
  }

  & > :nth-child(2) {
    margin-bottom: 4px;
  }

  & > :nth-child(3) {
    margin-bottom: 4px;

    display: flex;
    flex-direction: row;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;

  ul {
    margin: 0;
    padding: 0 15px;

    width: 100%;
  }

  li {
    margin: 12px 0;
    width: 100%;

    border-radius: 5px;
  }
`;

const FooterContainer = styled.div`
  padding: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > :nth-child(1) {
    margin-bottom: 10px;
  }
`;

const Category = styled(RefinementList)`
  ul {
    display: flex;
    flex-direction: row;
  }

  li {
    margin-right: 8px;

    :last-child {
      margin-right: 0;
    }
  }
`;

const ResultsContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    flex-direction: row;
  }

  & > :nth-child(1) {
    margin-right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 320px;
      overflow: hidden;

      img {
        width: auto;
        height: 320px;
      }
    }
  }

  & > :nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      width: 100%;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      overflow: hidden;
    }
  }
`;

const CategoryBadge = styled.span`
  padding: 3px 6px;
  margin-right: 8px;
  margin-left: 1px;
  font-size: 75%;
  color: ${theme.colors.black};
  background-color: ${theme.colors.grey20};
  border-radius: 6px;
  box-shadow: 0 0 3px #ddd;
  white-space: nowrap;
`;
